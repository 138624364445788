import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import LoginFormik from '../views/auth/LoginFormik';

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
const Error = Loadable(lazy(() => import('../views/auth/Error')));

const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));

const AdminStarter = Loadable(lazy(() => import('../views/admin/AdminStarter')));
const AdminReservation = Loadable(lazy(() => import('../views/admin/AdminReservation')));
const PublicComponent = Loadable(lazy(() => import('../views/public/PublicComponent')));
const ConfirmationComponent = Loadable(
  lazy(() => import('../views/public/client/ConfirmationComponent')),
);
const ChooseBarberComponent = Loadable(
  lazy(() => import('../views/public/client/ChooseBarberComponent')),
);
const CalendarComponent = Loadable(lazy(() => import('../views/public/client/CalendarComponent')));
const TimeComponent = Loadable(lazy(() => import('../views/public/client/TimeComponent')));
const ConfirmedComponent = Loadable(
  lazy(() => import('../views/public/client/ConfirmedComponent')),
);

const ThemeRoutes = [
  {
    path: '/',
    element: <PublicComponent />,
    children: [
      { path: '/', element: <ChooseBarberComponent /> },
      { path: '/:serviceId/:barberId', exact: true, element: <CalendarComponent /> },
      { path: '/:serviceId/:barberId/:date', exact: true, element: <TimeComponent /> },
      {
        path: '/:serviceId/:barberId/:date/:time/confirmation',
        exact: true,
        element: <ConfirmationComponent />,
      },
      {
        path: '/:serviceId/:barberId/:date/:time/confirmed',
        exact: true,
        element: <ConfirmedComponent />,
      },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/admin',
    exact: true,
    element: <FullLayout />,
    children: [
      { path: '/admin', element: <Navigate to="/admin/starter" /> },
      { path: '/admin/starter', exact: true, element: <AdminStarter /> },
      { path: '/admin/reservations', exact: true, element: <AdminReservation /> },
      { path: '/admin/*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/admin/auth',
    exact: true,
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'register', element: <RegisterFormik /> },
      { path: 'login', element: <LoginFormik /> },
    ],
  },
  { path: '404', element: <Error /> },
];

export default ThemeRoutes;
