const initialState = null;

const BarberReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_BARBER_REQUEST':
      return {
        ...state,
        error: null,
      };

    case 'FETCH_BARBER_SUCCESS':
      return {
        ...state,
        barbers: action.payload,
        error: null,
      };

    case 'FETCH_BARBER_FAILURE':
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default BarberReducer;
