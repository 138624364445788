import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { requestStarted, requestCompleted} from './loader/loaderActions';
import CustomizerReducer from './customizer/CustomizerSlice';
import UserReducer from './user/userReducers';
import AlertReducer from './alert/alertReducers';
import LoaderReducer from './loader/loaderReducer';
import BarberReducer from './barber/barberReducer';
import ServiceReducer from './service/serviceReducer';
import DateTimeReducer from './dateTime/dateTimeReducer';
import BarberShopReducer from './barberShop/barberShopReducer';
import ReservationReducer from './reservation/reservationReducer';

const loadingMiddleware = ({ dispatch }) => next => action => {
  if (action.type.endsWith('_REQUEST')) {
    dispatch(requestStarted());
  } else if (action.type.endsWith('_SUCCESS') || action.type.endsWith('_FAILURE')) {
    dispatch(requestCompleted());
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    user: UserReducer,
    alert: AlertReducer,
    loader: LoaderReducer,
    barbers: BarberReducer,
    services: ServiceReducer,
    dateTimes: DateTimeReducer,
    barberShop: BarberShopReducer,
    reservations: ReservationReducer,
  },
  middleware: [...getDefaultMiddleware(), loadingMiddleware],
});


export default store;
