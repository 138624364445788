const initialState = null;

const ReservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_RESERVATION_REQUEST':
      return {
        ...state,
        error: null,
      };

    case 'FETCH_RESERVATION_SUCCESS':
      return {
        ...state,
        reservations: action.payload,
        error: null,
      };

    case 'FETCH_RESERVATION_FAILURE':
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default ReservationReducer;
