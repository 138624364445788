const initialState = null;

const AlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return {
        ...state,
        alertType: action.payload.type,
        alertMessage: action.payload.message,
        isAlertVisible: true,
      };

    case 'HIDE_ALERT':
      return {
        ...state,
        isAlertVisible: false,
      };

    default:
      return state;
  }
};

export default AlertReducer;
