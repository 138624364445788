import axios from '../../axios-base';
import { showAlert } from '../alert/alertActions';

export const fetchUserRequest = () => ({
  type: 'FETCH_USER_REQUEST',
});

export const fetchUserSuccess = (user) => ({
  type: 'FETCH_USER_SUCCESS',
  payload: user,
});

export const fetchUserFailure = (error) => ({
  type: 'FETCH_USER_FAILURE',
  payload: error,
});

export const registerUser = (userData) => {
  return (dispatch) => {
    dispatch(fetchUserRequest());
    axios
      .post('admin/auth/register', userData)
      .then((response) => {
        dispatch({
          type: 'REGISTER_USER_SUCCESS',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(fetchUserFailure(error.message));
        dispatch(
          showAlert(
            'danger',
            'No se ha podido registrar el usuario, intente con otro nombre de usuario',
          ),
        );
      });
  };
};

export const fetchUser = () => {
  return (dispatch) => {
    dispatch(fetchUserRequest());

    axios
      .get('admin/auth/me')
      .then((response) => {
        dispatch(fetchUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUserFailure(error.message));
      });
  };
};

export const loginUser = (userData) => {
  return (dispatch) => {
    dispatch({
      type: 'LOGIN_USER_REQUEST',
    });

    axios
      .post('admin/auth/login', userData)
      .then((response) => {
        dispatch({
          type: 'LOGIN_USER_SUCCESS',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'LOGIN_USER_FAILURE',
          payload: error,
        });

        dispatch(showAlert('danger', 'Usuario o contraseña incorrectos'));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    axios
      .post('admin/auth/logout')
      .then(() => {
        dispatch({
          type: 'LOGOUT_USER_SUCCESS',
        });

        window.location.href = '/admin/auth/login';
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const updateUser = (body) => {
  return (dispatch) => {
    dispatch(fetchUserRequest());

    axios.put('/admin/me', body).then((response) => {
      dispatch(showAlert('success', 'Actualizado correctamente'));
      dispatch({
        type: 'UPDATE_USER_SUCCESS',
        payload: response.data,
      });
    }).catch(() => {
      dispatch(fetchUserFailure());
      dispatch(showAlert('danger', 'No se pudo actualizar'));
    });
  };
};
