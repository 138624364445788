const initialState = {
  isLoading: false,
};

const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_STARTED':
      return {
        ...state,
        isLoading: true,
      };
    case 'REQUEST_COMPLETED':
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default LoaderReducer;