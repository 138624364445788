const initialState = null;

const ServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SERVICE_REQUEST':
      return {
        ...state,
        error: null,
      };

    case 'FETCH_SERVICE_SUCCESS':
      return {
        ...state,
        services: action.payload,
        error: null,
      };

    case 'FETCH_SERVICE_FAILURE':
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ServiceReducer;
