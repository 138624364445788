import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { hideAlert } from '../../store/alert/alertActions';
import './AlertComponent.css';

const AlertComponent = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  useEffect(() => {
    if (alert?.isAlertVisible) {
      setTimeout(() => {
        dispatch(hideAlert());
      }, 10000);
    }
  }, [alert?.isAlertVisible, dispatch]);

  return (
    <div className="floating-alert">
      {alert?.isAlertVisible && <Alert color={alert?.alertType}>{alert?.alertMessage}</Alert>}
    </div>
  );
};

export default AlertComponent;
