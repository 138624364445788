const initialState = null;

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USER_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case 'FETCH_USER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'REGISTER_USER_SUCCESS':
      return {
        ...state,
        user: action.payload,
      };

    case 'LOGIN_USER_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'LOGIN_USER_SUCCESS':
    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case 'MERCADOLIBRE_AUTH_USER_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'MERCADOLIBRE_AUTH_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        mercadolibreReconnected: true,
        user: action.payload,
      };

    case 'MERCADOLIBRE_AUTH_USER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'LOGIN_USER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'LOGOUT_USER_SUCCESS':
      return {
        loading: false,
        user: null,
        error: null,
      };

    default:
      return state;
  }
};

export default UserReducer;
