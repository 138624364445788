const initialState = null;

const BarberShopReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_BARBERSHOP_REQUEST':
      return {
        ...state,
        error: null,
      };

    case 'FETCH_BARBERSHOP_SUCCESS':
      return {
        ...state,
        barberShop: action.payload,
        error: null,
      };

    case 'CREATE_RESERVATION_SUCCESS':
      return {
        ...state,
        isReserved: true,
      };

    case 'CREATE_RESERVATION_FAILURE':
      return {
        ...state,
        isReserved: false,
      };

    case 'FETCH_BARBERSHOP_FAILURE':
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default BarberShopReducer;
