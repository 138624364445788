const initialState = null;

const DateTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DATES_REQUEST':
      return {
        ...state,
        error: null,
      };

    case 'FETCH_DATES_SUCCESS':
      return {
        ...state,
        dates: action.payload,
        error: null,
      };

    case 'FETCH_TIMES_SUCCESS':
      return {
        ...state,
        times: action.payload,
        error: null,
      };

    case 'FETCH_DATES_FAILURE':
      return {
        ...state,
        error: action.payload,
      };

    case 'REMOVE_TIMES':
      return {
        ...state,
        times: null,
      };

    default:
      return state;
  }
};

export default DateTimeReducer;
